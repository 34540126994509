<template>
  <div class="footer-view" ref="footer">
    <!-- Footer para PC -->
    <div class="container footer__main-container">
      <div class="columns is-marginless">
        <div class="column is-12 footer-section">
          <img src="/images/logo_golstats_horizontal_black.svg" alt="Microsoft Logo" class="is-inline-flex" />
          <div class="has-divider-vertical"></div>
          <div class="termsofuse is-inline-flex right" @click="onClickTerminosDeUso">Términos de uso</div>
        </div>
      </div>
    </div>
    <!-- Footer para mobile y Desktop small -->
    <!-- <div class="mobile-footer" v-else>
      <BCarousel :autoplay="false" :arrow-hover="false">
        <BCarouselItem>
          <section class="hero">
            <div class="hero-body has-text-centered is-paddingless">
              <div>
                <img src="/images/logo_golstats_horizontal_black.svg" alt="Microsoft Logo" class="is-inline-flex" />
              </div>
              <div class="button is-inline-flex button-left is-rounded" @click="showSliderVideos">
                <span>Ver video</span>
                <span class="icon">
                  <i class="mdi mdi-play-circle-outline"></i>
                </span>
              </div>
            </div>
          </section>
        </BCarouselItem>
        <BCarouselItem>
          <section class="hero">
            <div class="hero-body has-text-centered is-paddingless">
              <p class="speech-1">Analiza como los <strong class="speech-2">Tipster profesionales</strong></p>
              <div class="button is-inline-flex button-left is-rounded" @click="showVideoModal">
                <span>Ver video</span>
                <span class="icon">
                  <i class="mdi mdi-play-circle-outline"></i>
                </span>
              </div>
            </div>
          </section>
        </BCarouselItem>
      </BCarousel>
    </div> -->
  </div>
</template>

<script>
import { clickVerTerminosDeUsoDesdeFooter } from '@/utils/analytics';
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {
      size: 769,
      terms: '/terms',
    };
  },
  created() {
    window.addEventListener('resize', () => this.setSize());
  },
  mounted() {
    this.setSize();
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isDesktop() {
      return this.size > 850;
    },
  },
  methods: {
    showSliderVideos() {
      this.$emit('showSliderVideos');
    },
    showVideoModal() {
      this.$emit('showVideoModal');
    },
    goTo(route) {
      let current = window.location.pathname;
      if (route !== current) this.$router.push(route);
      this.isActive = false;
    },
    setSize() {
      this.size = typeof this.$refs.footer === 'undefined' ? this.size : this.$refs.footer.clientWidth;
    },
    onClickTerminosDeUso() {
      clickVerTerminosDeUsoDesdeFooter(this.getPremiumAccount, this.getIsGolstats);
      this.goTo(this.terms);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-view {
  background-color: #f5f5f5;
  min-height: 69px;
  .footer__main-container {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .termsofuse {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.57px;
    text-decoration: underline;
    color: #595959;
    color: #3c3c3c;
    cursor: pointer;
  }
  .footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-section img {
    padding-right: 0.5rem;
  }
  .footer-section p {
    text-align: center;
    padding-right: 0.5rem;
  }
  .footer-section .has-divider-vertical {
    visibility: hidden;
  }
  .columns {
    padding: 0px 0px 5px 0px;
    .right {
      justify-content: flex-start;
    }
  }
  img {
    height: 40px;
  }
  .button-left {
    margin-left: 20px;
    color: white;
    background-color: #428ee6;
    font-size: 1rem;
    padding: 20px 30px;
    font-family: 'Circular-Std-Bold';
    .icon {
      margin-left: 10px !important;
      i {
        font-size: 1.5em;
      }
    }
  }
  .speech-1 {
    line-height: 1;
    font-family: 'Avenir-Pro-Medium';
    font-size: 1rem;
  }
  .speech-2 {
    font-family: 'Avenir-Pro-Bold';
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 768px) {
  .columns {
    padding: 0px;
  }
  .footer__main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .footer-view {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-section {
    img {
      height: 40px;
    }
    .has-divider-vertical {
      margin: 0px 5px;
      visibility: hidden;
    }
    .button-left {
      padding: 10px;
      .icon {
        margin: 5px !important;
      }
    }
  }
}
.mobile-footer {
  padding: 20px 0 10px;
  margin-top: 10px;
}

.column.is-offset-1,
.column.is-offset-1-tablet {
  margin-left: 40px;
}
@media screen and (max-width: 1100px) {
  .column.is-offset-1,
  .column.is-offset-1-tablet {
    margin-left: 20px;
  }
}
@media screen and (max-width: 950px) {
  .column.is-offset-1,
  .column.is-offset-1-tablet {
    margin-left: 5px;
    margin-right: 30px;
  }
  .footer-view {
    .speech-1 {
      font-size: 0.8rem !important;
    }
    .speech-2 {
      font-size: 0.9rem !important;
    }
    .button-left {
      margin-left: 5px;
    }
  }
}
@media screen and (max-width: 850px) {
  .footer-view {
    .speech-1 {
      font-size: 1rem !important;
      margin: 7px 0 10px 0;
    }
    .speech-2 {
      font-size: 1.1rem !important;
    }
  }
}
@media screen and (max-width: 415px) {
  .footer-section {
    .has-divider-vertical {
      visibility: visible !important;
    }
  }
}
</style>
